<!-- Cierres de Expedientes -->

<template>
  <div class="exp_Cierres">   
    <v-dialog v-model="dialog" content-class="modal" persistent> 
      <template v-slot:activator="{on}">
        <!-- v-on:click="btra_Mto({ accion:0 })" -->
        <!-- Botón para abrir Finder Documentos -->
        <v-btn 
          v-on='on'
          v-on:click="get_datos()"
          v-bind="$cfg.btra_cfg.standard"
          title="Cierres">
            <v-icon>{{'mdi-lock-outline'}}</v-icon>
        </v-btn>       
      </template>

      <div class="contenedor centrado">
        <v-sheet :elevation="4">        
          <!-- Cabecera -->
          <div class="cabecera">
            <base_Header
              v-if="Entorno.header.header"       
              :Entorno="Entorno.header"
              @onEvent="dialog=false">
            </base_Header>                  
          </div>

          <!-- Controles del Mto -->
          <div v-if="record" class="ctrls conflex contenedor max-width:1240px" > 
            <div class="columna" style="width:750px">
              <div class="conflex">
                <v-btn                 
                  @click="guardar()"
                  v-bind="$cfg.btra_cfg.standard"
                  title="Guardar Observaciones">
                    <v-icon>{{'mdi-content-save'}}</v-icon>
                </v-btn>

                <v-btn                 
                  style="margin-left:10px"
                  @click="get_datos"
                  :disabled="obsCierre"
                  v-bind="$cfg.btra_cfg.standard"
                  title="Cancelar">
                    <v-icon>{{'mdi-close'}}</v-icon>
                </v-btn> 
              </div>   
              
              <div class="conflex" style="padding-top:10px">
                <div class="columna" style="width:45%">
                  <div class="cab">Expediente<noscript></noscript></div>
                  <v-sheet v-bind="$cfg.styles.marco"> 
                    <div class="columna">
                      <vllabel
                        style="flex: 0 0 100%" 
                        label="Control"
                        :txt="record.control">              
                      </vllabel>

                      <vllabel
                        style="flex: 0 0 100%" 
                        label="Nombre"
                        :txt="record.fallename">              
                      </vllabel>
                      
                    </div>
                  </v-sheet> 
                </div>

              
                <div class="columna" style="width:55%;height:20px">
                  <div class="cab" style="width:90px">Cierres</div>
                  <v-sheet v-bind="$cfg.styles.marco"> 
                    <div class="columna">
                      <div class="conflex">
                        <div class="columna" style="width:30%">
                          <v-checkbox
                            v-bind="$checkbox"
                            v-model="schema.ctrls.chk1.value"                            
                            :label="schema.ctrls.chk1.label"
                            :disabled="tip!= 2 || schema.ctrls.chk2.value=='1' || !obsCierre"
                            dense
                            @change="showCierreApertura(1)">
                          </v-checkbox>
                        </div>

                        <div class="columna" style="width:70%">
                          <div style="font-size:15px;justify-right">
                            <v-icon :style="schema.ctrls.chk1.value=='1'? 'color:red' : 'color:green'" small>mdi-account</v-icon>
                            <!-- {{ `${$options.filters.fechahr(schema.ctrls.chk1f.value)} - ${schema.ctrls.chk1u.value}` }} -->
                            {{ `${schema.ctrls.chk1m.value}` }}
                          </div>
                        </div>
                      </div>

                      <div class="conflex">
                        <div class="columna" style="width:30%">                         
                          <v-checkbox
                            v-bind="$checkbox"
                            v-model="schema.ctrls.chk2.value"
                            :label="schema.ctrls.chk2.label"                       
                            :disabled="tip!= 2 || schema.ctrls.chk1.value=='0' || schema.ctrls.chk3.value=='1' || !obsCierre"
                            dense
                            @change="showCierreApertura(2)">
                          </v-checkbox>
                        </div>

                        <div class="columna" style="width:70%">
                          <div style="font-size:15px">
                            <v-icon :style="schema.ctrls.chk2.value=='1'? 'color:red' : 'color:green'" small>mdi-account</v-icon>
                            <!-- {{ `${$options.filters.fechahr(schema.ctrls.chk2f.value)} - ${schema.ctrls.chk2u.value}` }} -->
                            {{ `${schema.ctrls.chk2m.value}` }}
                          </div>
                        </div>
                      </div>                      

                      <div class="conflex">
                        <div class="columna" style="width:30%">                          
                          <v-checkbox
                            v-bind="$checkbox"
                            v-model="schema.ctrls.chk3.value"
                            :label="schema.ctrls.chk3.label"     
                            :disabled="tip!= 2 || schema.ctrls.chk2.value=='0' || !obsCierre"
                            dense
                            @change="showCierreApertura(3)">
                          </v-checkbox>
                        </div>

                        <div class="columna" style="width:70%">
                          <div style="font-size:15px">
                            <v-icon :style="schema.ctrls.chk3.value=='1'? 'color:red' : 'color:green'" small>mdi-account</v-icon>                            
                            {{ schema.ctrls.chk3m.value }}
                          </div>
                        </div>
                      </div>
                    </div>                    
                  </v-sheet>                   
                </div>
              </div>               

              <div class="conflex">
                <div class="columna" style="width:100%;">
                  <div class="cab">Por cumplimentar para cierre</div>
                  <v-sheet v-bind="$cfg.styles.marco"> 
                    <div v-if="obsCierre">
                      <ctrlobs3
                        :schemaComponente="schema.ctrls.chkdef"
                        :schema="schema"
                        :record="record"
                        :edicion=true>
                      </ctrlobs3>
                    </div>
                    <div v-else>       
                      <h2 style="color:red">{{ titulo }}</h2>                
                      <v-select
                        v-bind="$select"
                        v-model="schema.ctrls.motivo.value"                        
                        :label="schema.ctrls.motivo.label"
                        :items="itemsCierreApertura"
                        dense
                        item-value="name"
                        item-text="name">
                      </v-select>
          
                      <v-textarea
                        v-bind="$textarea"
                        v-model="schema.ctrls.comentario.value"
                        :label="schema.ctrls.comentario.label"
                        rows="3"
                        no-resize>                      
                      </v-textarea>
                    </div>
                  </v-sheet> 
                </div>
              </div> 

            </div>
          </div>
        </v-sheet> 
      </div>
    </v-dialog>    
  </div>
</template>



<script>

  import { mixinMto } from "@/mixins/mixinMto.js";
  import { mixinMtoArray } from "@/mixins/mixinMtoArray.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const ctrlobs3 = () => plugs.groute("ctrlobs3.vue", "comp");
    
  export default {
    mixins: [mixinMto, mixinMtoArray],
    components: { base_Header, ctrlobs3 },
    props: {
      padre: { type:String, default: '' }, 
      accion: { type:String, default: '' },
      accionRow: { type:Object, default: null},
      tip: { type:Number, default: 1},
      ctb: { type: [String, Boolean], default: "0" },    
      show: { type: [String, Boolean], default: "1" },    
      componenteTipo: { type:String, default:'M' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema: {
          ctrls: {
            chk1: { f:'chk1', label:'CTD', value:"0"},
            chk2: { f:'chk2', label:'ADMON', value:"0"},
            chk3: { f:'chk3', label:'CIERRE', value:"0"},
            chk1f: { f:'chk1f', label:'Conf. CTD', value:"0"},
            chk2f: { f:'chk2f', label:'Conf. ADMON', value:"0"},
            chk3f: { f:'chk3f', label:'CIERRE', value:"0"},
            chk1u: { f:'chk1u', label:'Conf. CTD', value:"0"},
            chk2u: { f:'chk2u', label:'Conf. ADMON', value:"0"},            
            chk3u: { f:'chk3u', label:'CIERRE', value:"0"},
            chk1m: { f:'chk1m', label:'Conf. CTD', value:"0"},
            chk2m: { f:'chk2m', label:'Conf. ADMON', value:"0"},
            chk3m: { f:'chk3m', label:'CIERRE', value:"0"},
            chkdef: { f:'', label:'', value:"",
              comp: { 
                type:"obs3",
                fields:{ gestion:"chkdef" },
                valueaux:""                            
              }
            },
            
            digf_rec: { f:'digf_rec', label:'Recepción', value:"", ini:'Ordinario'},
            digf_obs: { f:'digf_obs', label:'Observaciones', value:""},
            motivo: { f:'motivo', label:'Motivo', value:"" },
            comentario: { f:'comentario', label:'Comentario', value:"" }
          }
        },
        api:'',
        stName:'stMexp_Cierres',

        dialog:false,
        itemsRecepcion: [],
        itemsCierreApertura:[],
        obsCierre:true,
        chkPulsado:0,
        titulo:''
      };
    },


    created() {
      this.ini_data();
    },


    computed: {     

      chk2() {
        return (this.schema.ctrls.chk2.value=='1' ? true : false);
      },

    },
   

    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // configuro Entorno 
        this.Entorno.header.titulo = "Configuración Cierres"; 
      
        // items adicionales
        this.itemsRecepcion= [
          { id:"0", name:"Ordinario"},
          { id:"1", name:"Certificado"},
          { id:"2", name:"Mensajeria"},
          { id:"3", name:"Otros"},
          { id:"4", name:"Firma Digital"},
        ];
      },


      // al terminar de cargar el registro, si la factura aún no ha sido
      // digitalizada, inicializo schema
      record_cargado() {
        if (this.contabilizada) return;
        this.record2ctrl({}, this.schema.ctrls, true);
      },


      // obtengo datos del cierre
      async get_datos() {     
        // inicializo datos
        this.itemsCierreApertura= [];
        this.obsCierre= true;
        this.chkPulsado= 0;

        // llamada API
        let args = { tipo:'fnc', accion: 'expediente', fn_args: { 
          accion:'cierre',
          fnt:'get',
          id: this.accionRow.id
        }};
        console.log('args get_datos: ', args);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        console.log('apiResult get_datos: ', apiResult);        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        console.log('*get_datos',apiResult.r[0]);

        this.record2ctrl(apiResult.r[0], this.schema.ctrls); 
        this.$store.commit(this.stName + '/data2State', { prop:'record', value:apiResult.r[0] }); 
      },


      // muestro opciones de Cierre / Apertura
      showCierreApertura(chk) {
        //let V=(this.schema.ctrls['chk' + chk].value=='0' ? '1' : '0');
        let V=Number(this.schema.ctrls['chk' + chk].value);

        console.log('*** showCierreApertura ', chk, V, this.ctb);

        //cierre ctd validaciones - ver igual que en exp_M_incidencias
        

        //cierre admon sino contabilizada
        if (chk==2 && V==1 && !this.ctb) {          
          //this.schema.ctrls.chk2.value=0;
          this.$root.$alert.open('<br> IMPOSIBLE CERRAR ADMON, <b>EXISTEN FACTURAS SIN CONTABILIZAR</b><br>', 'error');   
          
          this.get_datos(); //leemos de nuevo
          return;
        }

        this.itemsCierreApertura= (V==1 ? this.$store.state.datos_iniciales.motivos_cierre : this.$store.state.datos_iniciales.motivos_reapertura);
        this.obsCierre= false;
        this.chkPulsado= chk;
        this.titulo= (V==1? "CIERRE " : "APERTURA ") + (chk==1? "CTD" : chk==2? "ADMON" : "") + " EXPEDIENTE";
      },
     

      // guardar observaciones / Cierre
      guardar() {        
        if (this.obsCierre) return this.guardarObs();
        this.guardarCierre();
      },


      // guardar Observaciones
      guardarObs() {
        if (!this.schema.ctrls.chkdef.comp.valueaux) {
          this.$root.$alert.open('Debe introducir una observación', 'error');
          return;
        }

        this.guardarObs_continue();
      },

      async guardarObs_continue() {
        let args = { tipo:'fnc', accion: 'expediente', fn_args: { 
          accion:'cierre',
          fnt:'obs',
          id: this.accionRow.id,
          txt: this.schema.ctrls.chkdef.comp.valueaux
        }};
        console.log('args guardarObs_continue: ', args);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        console.log('apiResult guardarObs_continue: ', apiResult);        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.get_datos();
      },


      // guardar Cierre
      async guardarCierre() {
         if (!this.schema.ctrls.motivo.value) {
          this.$root.$alert.open('Debe introducir un MOTIVO', 'error');
          return;
        }

        let val= this.schema.ctrls['chk' + this.chkPulsado].value;
        
        await this.$root.$alert.open('Deseas ' + (val=="1"? "ACEPTAR":"CANCELAR") + ' el cierre ' + (this.chkPulsado==1? "CTD":this.chkPulsado==2? "ADMON": "") + " del expediente?", "confirm")
          .then(r=> { if (r) { this.cierreAPI(); }          
        });
      },


      // 
      async cierreAPI() {       
        let args = { tipo:'fnc', accion: 'expediente', fn_args: { 
          accion:'cierre',
          fnt:'set',
          id: this.accionRow.id,
          tip:this.chkPulsado,
          txt:this.schema.ctrls.comentario.value,
          chk:this.schema.ctrls['chk' + this.chkPulsado].value=='1'? 1 : 0,
          mot:this.schema.ctrls.motivo.value
        }};
        
        console.log('args cierreAPI: ', args);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        console.log('apiResult cierreAPI: ', apiResult);        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
        
        this.dialog= false;
        this.$emit('cierreGuardado');
      },

    }
  };
</script>
